@import
    "settings/base",
    "settings/breakpoints";

@import
    "tools/functions",
    "tools/mixins",
    "tools/responsive";

@import
    "components/btn",
    "components/check",
    "components/field";

/*------------------------------------*\
    $WOOCOMMERCE - FORM FIELDS
\*------------------------------------*/

.woocommerce form {
    .form-row {
        fieldset {
            border:none;

            legend {
                display:none;
            }
        }

        input[type=tel],
        input[type=text],
        input[type=email],
        input[type=number],
        input[type=password],
        .input-text,
        textarea {
            @extend .field-control;
            line-height: normal;
        }
        textarea {
            min-height:143px;
        }
        .select2-selection--single,
        select {
            @extend .field-control;
            padding-right:58px;
            background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24,28.5L24,28.5L24,28.5L5.3,10.6c-0.5-0.5-1.2-0.4-1.7,0l-3.3,3.2c-0.5,0.5-0.5,1.2,0,1.7l22.8,21.9c0.2,0.2,0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.3l22.8-21.9c0.5-0.5,0.5-1.2,0-1.7l-3.3-3.2c-0.5-0.5-1.2-0.5-1.7,0L24,28.5z"/></svg>');
            background-size:14px;
            background-position:right 20px center;
            background-repeat:no-repeat;

            &::-ms-expand {
                display: none;
            }
        }
        .select2-selection--single {
            height:auto;

            .select2-selection__rendered {
                padding:0;
                line-height:1.57143;
            }
            .select2-selection__arrow {
                display:none;
            }
        }
        label.checkbox,
        label.woocommerce-form__label-for-checkbox {
            @extend .check;

            input[type=checkbox] {
                @extend .check-control;
            }
            span {
                @extend .check-body;
            }
        }
        button.button {
            @extend .btn;
            border-radius:0;
        }
    }
}

/*------------------------------------*\
    PASSWORD PROTECTION
\*------------------------------------*/

.ppw-ppf-field-container {
    padding-top:24px;
    flex-wrap: wrap;
}
.ppw-ppf-field-container,
.ppw-pwd-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.ppw-pwd-label {

    input[type=password] {
        @include font-size(14px, 22px);
        width: 100%;
        display: block;
        padding: 16px 22px;
        color: $colour-ui-dark;
        border: 1px solid #e6e6e6;
        font-weight: $font-weight-semibold;
        background-color: $colour-ui-light;
        border-radius: 0;
        appearance: none;
        transition: border-color $base-anim-speed;
        line-height: normal;

        &:focus {
            border-color: $colour-secondary;
            outline: none;
        }
    }
}
.ppw-pwd-submit-btn {
    @include font-size(16px, 24px);
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: halve($base-spacing-unit) $base-spacing-unit;
    background-color: $colour-primary;
    color: $colour-ui-light;
    border: none;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    transition: color $base-anim-speed, background-color $base-anim-speed;
    line-height: normal;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:hover {
        background-color: $colour-quaternary;
        color: $colour-ui-light;
    }
}