/*------------------------------------*\
    $CHECK
\*------------------------------------*/

.woocommerce form {
    .form-row {
        .check {
            position: relative;
            display: inline-block;
            vertical-align: top;
        }

        .check-control {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked ~ .check-body {
                &::after {
                    opacity: 1;
                }
            }

            &:focus ~ .check-body {
                &::before {
                    border-color: $colour-primary;
                }
            }
        }

        .check-body {
            display: inline-block;
            @include font-size(14px, 16px);
            line-height: 1.1;
            padding-left: $base-spacing-unit + 16px;

            &::before,
            &::after {
                content: "";
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: 0 - halve($base-spacing-unit);
                width: $base-spacing-unit;
                height: $base-spacing-unit;
                border: 2px solid $colour-ui;
                transition: border-color $base-anim-speed;
                margin-right: 16px;

                .box-ui & {
                    background-color: $colour-ui-light;
                    border-color: transparent;
                }

                .is-invalid & {
                    border-color: $colour-invalid !important;
                }
            }

            &::after {
                background-color: $colour-primary;
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 15px;
                transform: translate3d(-50%, -50%, 0);
                opacity: 0;
                transition: opacity $base-anim-speed;
            }

            .is-invalid & {
                // color: $colour-invalid;
            }
        }

        .check-switch {
            .check {
                vertical-align: top;
            }

            .check-body {
                padding-left: 60px;

                &::before {
                    margin-top: -12px;
                    width: 48px;
                    height: 24px;
                    border-radius: 99px;
                    border-color: $colour-ui;
                    background-color: $colour-ui;
                    box-shadow: inset 0 0 0 0 $colour-ui;
                    transition: background-color $base-anim-speed, border-color $base-anim-speed, box-shadow $base-anim-speed;
                }

                &::after {
                    opacity: 1;
                    background-color: $colour-ui-light;
                    width: 24px;
                    height: 24px;
                    border: 2px solid $colour-ui;
                    border-radius: 99px;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    transition: transform $base-anim-speed ease-in-out, border-color $base-anim-speed;
                }
            }

            .check-control {
                &:focus ~ .check-body {
                    &::before {
                        @include focus;
                        border-color: $colour-ui;
                    }
                }

                &:checked ~ .check-body {
                    &::before,
                    &::after {
                        border-color: $colour-primary;
                    }

                    &::before {
                        box-shadow: inset 0 0 0 13px $colour-primary;
                        background-color: $colour-primary;
                        transition-duration: treble($base-anim-speed), $base-anim-speed, $base-anim-speed, $base-anim-speed;
                    }

                    &::after {
                        transform: translate(100%, -50%);
                    }
                }
            }

            .check-text {
                font-weight: $font-weight-semibold;
            }
        }
    }
}
