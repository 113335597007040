//------------------------------------*\
//  $SETTINGS-BASE
//------------------------------------*/

// High level base settings
$base-font-size:            15px;
$base-line-height:          25px;
$base-spacing-unit:         30px;
$base-text-colour:          #262626;
$base-background-colour:    #fff;
$base-font-fallback:        sans-serif;
$base-font:                 'Source Sans Pro', #{$base-font-fallback};
$base-anim-speed:           300ms;


// Colour Palette
$colour-primary:            #447532;
$colour-secondary:          #005c34;
$colour-tertiary:           #e8c416;
$colour-quaternary:         #1c3d2c;
$colour-quinary:            #142119;
$colour-royal:              #440561;
$colour-royal-alt:          #8b724b;

$colour-ui-light:           #fff;
$colour-ui:                 #e6e6e6;
$colour-ui-dark:            $base-text-colour;

$colour-valid:              $colour-primary;
$colour-invalid:            #ee2b2f;


// #fff
// #e6e6e6
// #dbdbdb - .typography ul border-left, .table td border-bottom
// #808080
// #333


// Typefaces
$font-primary:              $base-font;


// Weights
$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-regular:       $font-weight-normal;
$font-weight-semibold:      600;
$font-weight-bold:          700;
