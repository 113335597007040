/*------------------------------------*\
    $FIELD
\*------------------------------------*/

/**
 * Use a containing element for inputs so we can add pseudo-elements
 */

 .woocommerce form {
    .form-row {
        .field-list {
            margin-bottoM: $base-spacing-unit;

            &.grid {
                margin-bottom: $base-spacing-unit - 6px;
            }
        }

        .field {
            position: relative;
            margin-bottom: 6px;

            .field-inline > & {
                display: flex;
                align-items: baseline;
            }

            .field-shift > & {
                margin-bottom: $base-spacing-unit;

                .filters-options & {
                    margin-bottom: 8px;
                }
            }
        }

        .field-label {
            @include font-size(13px, 26px);
            display: inline-block;
            text-transform: uppercase;
            font-weight: $font-weight-bold;

            .field-inline & {
                margin-right: 15px;
            }

            .field-required & {
                &::after {
                    content: "*";
                }
            }
        }


        /**
        * Use a class instead of a huge input[type=*] selector
        */

        .field-control {
            @include font-size(14px, 22px);
            width: 100%;
            display: block;
            padding: 16px 22px;
            color: $colour-ui-dark;
            border: 1px solid #e6e6e6;
            font-weight: $font-weight-semibold;
            background-color: $colour-ui-light;
            border-radius: 0;
            appearance: none;
            transition: border-color $base-anim-speed;

            &:focus {
                border-color: $colour-secondary;
                outline: none;
            }

            .band-ui & {
                border-color: transparent;
            }

            .box-ui & {
                border-color: transparent;

                &:focus {
                    border-color: $colour-secondary;
                }
            }

            .box-ui-light & {
                border-color: #e6e6e6;

                &:focus {
                    border-color: $colour-secondary;
                }
            }

            .is-invalid & {
                &,
                &:focus {
                    border-color: $colour-invalid;
                }
            }

            &::placeholder,
            &.is-clean {
                color: #808080;
                font-weight: $font-weight-normal;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .field-select {
            position: relative;

            .field-control {
                padding-right: 55px;
            }

            ::-ms-expand {
                display: none;
            }

            .icon {
                position: absolute;
                top: 50%;
                right: 28px;
                transform: translate(50%, -50%) rotate(0.25turn);
                color: #98a59f;
                pointer-events: none;
            }
        }

        .field-icon {
            display: none;
        }


        .field-feedback {
            @include font-size(15px, 1.5);
            color: $colour-invalid;
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transition: max-height $base-anim-speed $base-anim-speed, opacity $base-anim-speed;

            &::before {
                content: "";
                display: block;
                padding-top: quarter($base-spacing-unit);
            }

            .is-invalid > & {
                max-height: 45px; // 12px * 1.5 * 2;
                opacity: 1;
                transition: max-height $base-anim-speed, opacity $base-anim-speed $base-anim-speed;
            }

            @include media-query(desk) {
                .newsletter & {
                    @include font-size(13px, 1);
                    position: absolute;
                    top: 100%;
                }
            }
        }
    }
}
